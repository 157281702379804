import React from 'react'
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Link,
  Font,
} from '@react-pdf/renderer'

import logo from '../../../images/logo-colored.jpg'
import OpenSansRegular from '../../../../static/fonts/OpenSans-Regular.ttf'
import OpenSansBold from '../../../../static/fonts/OpenSans-Bold.ttf'

Font.register({
  family: 'Open Sans',
  fonts: [{ src: OpenSansRegular }, { src: OpenSansBold, fontWeight: 700 }],
})

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    fontFamily: 'Open Sans',
    fontSize: 10,
    padding: 30,
  },
  logo: {
    width: 155.6,
    height: 120.8,
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    marginTop: 16,
    textAlign: 'center',
    textDecoration: 'underline',
  },
  heading1: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 12,
  },
  heading2: {
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 4,
  },
  heading3: {
    fontSize: 12,
    fontWeight: 700,
    marginBottom: 4,
  },
  textBold: {
    fontWeight: 700,
  },
  textUnderline: { textDecoration: 'underline' },
  textBU: {
    fontWeight: 700,
    textDecoration: 'underline',
  },
  sig: {
    width: 48,
    height: 36,
  },
})

const CreditCardAuthPdf = ({ data }) => (
  <Document style={{ width: '100%' }}>
    <Page size="LETTER" style={styles.page}>
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Image style={styles.logo} source={logo} />
        </View>
        <View
          style={{
            marginLeft: 224,
            marginTop: 16,
            lineHeight: 2,
            fontWeight: 700,
          }}
        >
          <Text>RoadLINX Inc.</Text>
          <Text>501 Rowntree Dairy Road, Unit 4</Text>
          <Text>Woodbridge, ON L4L 8H1</Text>
          <Text>905-760-1141</Text>
          <Link src="mailto:accounting@roadlinx.com">
            accounting@roadlinx.com
          </Link>
        </View>
      </View>

      <Text style={styles.title}>Credit Card Payment Authorization Form</Text>
      <Text style={{ marginTop: 16 }}>
        Complete and sign this form to authorize RoadLINX Inc to debit your
        credit card listed below.
      </Text>
      <Text style={{ marginTop: 16 }}>
        By signing this form you give RoadLINX Inc permission to debit your
        account for the amount stated, on or after the pickup date with an
        additional terminal service charge of 2.5%.
      </Text>

      <Text style={{ marginTop: 8, width: '100%', borderBottomWidth: 2 }}>
        {' '}
      </Text>

      <Text style={[styles.heading3, { marginTop: 8 }]}>
        Please complete the information below:
      </Text>
      <Text>
        I <Text style={styles.textBU}>{data.name}</Text> authorize RoadLINX Inc
        to charge my credit card for agreed upon transactions. I understand that
        my information will be saved on file for future transactions on my
        account.
      </Text>

      <View style={{ marginTop: 16, flexDirection: 'row' }}>
        <Text style={{ width: '65%' }}>
          Billing Address: {data.billingAddress}
        </Text>
        <Text style={{ width: '35%' }}>Phone # {data.phone}</Text>
      </View>

      <View style={{ marginTop: 16, flexDirection: 'row' }}>
        <Text style={{ width: '65%' }}>
          City, State, Zip: {data.city}, {data.state}, {data.zip}
        </Text>
        <Text style={{ width: '35%' }}>Email: {data.email}</Text>
      </View>

      <View style={{ marginTop: 16, flexDirection: 'row' }}>
        <Text>Account Type: {data.accountType}</Text>
      </View>

      <View style={{ marginTop: 16, flexDirection: 'row' }}>
        <Text>Company Name: {data.company}</Text>
      </View>

      <View style={{ marginTop: 16, flexDirection: 'row' }}>
        <Text>Cardholder Name: {data.cardholder}</Text>
      </View>

      <View style={{ marginTop: 16, flexDirection: 'row' }}>
        <Text>Card Number: {data.cardNumber}</Text>
      </View>

      <View style={{ marginTop: 16, flexDirection: 'row' }}>
        <Text>Expiration Date: {data.expiration}</Text>
      </View>

      <View style={{ marginTop: 16, flexDirection: 'row' }}>
        <Text>
          CVV2 (3 digit number on back of Visa/MC, 4 digits on front of AMEX):{' '}
          {data.cvv2}
        </Text>
      </View>

      <View style={{ marginTop: 32, flexDirection: 'row' }}>
        <Text style={{ marginRight: 8 }}>Signature:</Text>
        <Image style={styles.sig} source={data.signature} />
        <Text style={{ marginLeft: 48 }}>
          Date: ____________________________
        </Text>
      </View>

      <Text style={{ marginTop: 16 }}>
        I authorize RoadLINX Inc to charge the credit card indicated in this
        authorization form according to the terms outlined above. This payment
        authorization is for the goods/services rendered by RoadLINX Inc, for
        the amount indicated above only. This credit card form will stay on file
        for business going forward when credit terms are not set by both
        parties. I certify that I am an authorized user of this credit card and
        that I will not dispute the payment with my credit card company; so long
        as the transaction corresponds to the terms indicated in this form.
      </Text>
    </Page>
  </Document>
)

export default CreditCardAuthPdf
