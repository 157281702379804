import {
  Button, FormControlLabel, FormLabel, InputAdornment, Radio,
  RadioGroup, TextField, Tooltip
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import { pdf } from '@react-pdf/renderer'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SignatureCanvas from 'react-signature-canvas'
import Footer from '../components/Footer'
import CreditCardAuthPdf from '../components/Forms/CreditCardAuth/CreditCardAuthPdf'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { encodeToFormData, getFormattedTime } from '../utils'



class CreditCardAuthForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      status: 'init',
      formData: {
        name: '',
        billingAddress: '',
        phone: '',
        city: '',
        state: '',
        zip: '',
        email: '',
        accountType: '',
        company: '',
        cardholder: '',
        cardNumber: '',
        expiration: '',
        cvv2: '',
      },
    }
    this.sigPad = null
  }

  handleChange = e => {
    const { name, value } = e.target
    if (name.includes('.')) {
      const [parent, child] = name.split('.')
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          [parent]: { ...prevState.formData[parent], [child]: value },
        },
      }))
    } else {
      this.setState(prevState => ({
        formData: { ...prevState.formData, [name]: value },
      }))
    }
  }

  handleSubmit = async e => {
    e.preventDefault()

    this.setState({ status: 'submitting' })

    const { formData: data } = this.state
    const formData = encodeToFormData({
      'form-name': e.target.getAttribute('name'),
      ...data,
    })

    // append pdf: generated by the react-pdf
    data.signature = this.sigPad.getTrimmedCanvas().toDataURL('image/png')
    const now = getFormattedTime()
    const blob = await pdf(<CreditCardAuthPdf data={data} />).toBlob()
    formData.append(
      'file',
      blob,
      `credit-card-payment-authentication-form_${now}.pdf`
    )

    fetch('/', {
      method: 'POST',
      body: formData,
    })
      .then(res => {
        if (res.ok) this.setState({ status: 'success' })
        else this.setState({ status: 'failed' })
        //console.info(res)
      })
      .catch(err => {
        this.setState({ status: 'failed' })
        //console.error(err)
      })
  }

  render() {
    const { status, formData: values } = this.state
    return (
      <Layout>
        <SEO title="Credit Card Authentication Form | RoadLinx Shipping Company" />
        <section className="credit-application-form">
          <Container fluid className="credit-application-form__header">
            <h1 className="credit-application-form__title">
              Credit Card Payment Authorization Form
            </h1>
          </Container>
          <Container
            className="credit-application-form__container"
            fluid={false}
          >
            <form
              name="Credit Card Payment Authentication Form"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              hidden
            >
              <input type="text" name="name" />
              <input type="text" name="billingAddress" />
              <input type="text" name="phone" />
              <input type="text" name="city" />
              <input type="text" name="state" />
              <input type="text" name="zip" />
              <input type="text" name="email" />
              <input type="text" name="accountType" />
              <input type="text" name="company" />
              <input type="text" name="cardholder" />
              <input type="text" name="cardNumber" />
              <input type="text" name="expiration" />
              <input type="text" name="cvv2" />
              <input type="file" name="file" accept=".pdf" />
            </form>
            {(status === 'init' || status === 'submitting') && (
              <form
                name="Credit Card Payment Authentication Form"
                method="POST"
                onSubmit={this.handleSubmit}
              >
                <input
                  type="hidden"
                  name="form-name"
                  value="Credit Card Payment Authentication Form"
                />
                <input
                  type="hidden"
                  name="bot-field"
                  onChange={this.handleChange}
                />
                <Row className="mt-3">
                  <Col md={4}>
                    <TextField
                      fullWidth
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                  <Col md={4}>
                    <TextField
                      fullWidth
                      placeholder="Phone"
                      name="phone"
                      value={values.phone}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                  <Col md={4}>
                    <TextField
                      fullWidth
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={3}>
                    <TextField
                      fullWidth
                      placeholder="Billing Address"
                      name="billingAddress"
                      value={values.billingAddress}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      fullWidth
                      placeholder="City"
                      name="city"
                      value={values.city}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      fullWidth
                      placeholder="State"
                      name="state"
                      value={values.state}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      fullWidth
                      placeholder="Zip"
                      name="zip"
                      value={values.zip}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                </Row>
                <Row className="m-0 mt-5">
                  <FormLabel component="label">Account Type:</FormLabel>
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="accountType"
                    value={values.accountType}
                    onChange={this.handleChange}
                    style={{ marginLeft: '10px', marginTop: '-10px' }}
                  >
                    <FormControlLabel
                      className="customGray"
                      value="Visa"
                      control={<Radio />}
                      label="Visa"
                    />
                    <FormControlLabel
                      className="customGray"
                      value="MasterCard"
                      control={<Radio />}
                      label="MasterCard"
                    />
                    <FormControlLabel
                      className="customGray"
                      value="AMEX (3.5%)"
                      control={<Radio />}
                      label="AMEX (3.5%)"
                    />
                    <FormControlLabel
                      className="customGray"
                      value="Discover"
                      control={<Radio />}
                      label="Discover"
                    />
                  </RadioGroup>
                </Row>
                <Row>
                  <Col md={3}>
                    <TextField
                      fullWidth
                      placeholder="Company Name"
                      name="company"
                      value={values.company}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      fullWidth
                      placeholder="Cardholder Name"
                      name="cardholder"
                      value={values.cardholder}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      fullWidth
                      placeholder="Card Number"
                      name="cardNumber"
                      value={values.cardNumber}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                  <Col md={2}>
                    <TextField
                      fullWidth
                      placeholder="Expiration Date"
                      name="expiration"
                      value={values.expiration}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                  <Col md={1}>
                    <TextField
                      fullWidth
                      placeholder="CVV2"
                      name="cvv2"
                      value={values.cvv2}
                      onChange={this.handleChange}
                      size="medium"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="CVV2 (3 digit number on back of Visa/MC, 4 digits on front of AMEX)">
                              <InfoIcon />
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Col>
                </Row>

                <Row className="mt-5 align-items-center">
                  <Col lg={1}>
                    <div className="h5">Signature </div>
                    <div className="mt-5">
                      <Button
                        variant="text"
                        color="secondary"
                        size="small"
                        onClick={() => this.sigPad.clear()}
                      >
                        Clear
                      </Button>
                    </div>
                  </Col>
                  <Col>
                    <SignatureCanvas
                      ref={ref => {
                        this.sigPad = ref
                      }}
                      dotSize={1}
                      throttle={2}
                      canvasProps={{
                        width: 240,
                        height: 180,
                        className: 'sigCanvas',
                        style: { border: '2px solid black' },
                      }}
                    />
                  </Col>
                </Row>

                <Row className="m-0 mt-5">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={status === 'submitting'}
                  >
                    Submit
                  </Button>
                </Row>
              </form>
            )}
            {status === 'success' && (
              <div className="w-100 mt-5 text-center">
                <div className="mb-2 text-success">
                  <CheckCircleIcon style={{ fontSize: 24 }} />
                </div>
                <div className="mb-4">
                  Your form has been submitted successfully.
                </div>
              </div>
            )}
            {status === 'failed' && (
              <div className="w-100 mt-5 text-center">
                <div className="mb-2 text-danger">
                  <ErrorIcon style={{ fontSize: 24 }} />
                </div>
                <div className="mb-4 h5">Oops! something went wrong...</div>
                <div>
                  Please try reloading the page and submit the form again.
                </div>
              </div>
            )}
          </Container>
        </section>
        <Footer />
      </Layout>
    )
  }
}

export default CreditCardAuthForm
